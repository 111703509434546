import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Info.css';

function Info(props) {

  const nodeRef = useRef(null);

  return (
    
    <CSSTransition in={true} nodeRef={nodeRef} timeout={200} classNames="fadeOut">
      <div className="Info-container" ref={nodeRef}>

            <div className="Info-close">
                <a href="/" className="Info-close-button" title="Close">
                  <i className="material-icons">close</i>
                </a>
            </div>

            <div className="Info-text">

              <p style={{ margin: 0, textAlign: "center" }}>
                <img src="/neuron.png" alt="Neuron" />
              </p>
              
              <p>
                The general idea behind DonQuixote is to attempt to capitalize on
                &nbsp;<a rel="noreferrer noopener" href="https://en.wikipedia.org/wiki/Hebbian_theory" target="_blank" className="Info-link">Hebbian learning</a>&nbsp;
                by using your primary language as the basis and springboard for learning a new language.
                As a child, we are constantly associating words and phrases contextually. When we're young, we see a ball, and a parent says "ball." 
                After enough conditioning, we associate the word ball with its image. By stepping through a DonQuixote 
                text and reciting your primary language aloud as well as the language you're trying to learn, we believe that you're cutting 
                down learning time drastically by activating the same associative learning mechanism used as a child.
              </p>

              <p>
                With modern language learning apps, the focus is very much on the new word or phrase you're 
                trying to learn. While this seems like the obvious track, we take the opposite approach and focus on the words you know in your
                 primary language instead. We then introduce you to the associated words and phrases in the other language. This method should enable the 
                 brain to attribute meaning to the new language. 
              </p>

              <p>
                As you step through the lessons, you may notice that your primary language sounds stranger than usual. 
                The sacrifice in usual word order in your primary language enables you to think contextually in the 
                language you're trying to learn. When you get to the <span className="Info-green">highlighted green text</span>, you'll notice a sense of understanding that you didn't have before.
                 You may also become aware if you hit the translate button(<span className="material-icons" style={{ verticalAlign: "-4px" }}>translate</span>), you can read and understand the 
                entire text, especially if you go through it more than once.
              </p>

              <p>
                That's our handwavy theory behind it anyway. You'll have to try it for yourselves and see how effective DonQuixote is for you. We believe all 
                language learning apps are useful in your language learning journey and hope that you continue to use them as well as DonQuixote going forward.
              </p>

              <p>
                <a href="/" className="Info-link">Get Started</a>
              </p>

            </div>

      </div>
    </CSSTransition>
  );
}

export default Info;
