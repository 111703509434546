import { BookLoader } from "./Books";

BookLoader.load().then((books) => {

    const initBookTitle = (localStorage && localStorage.getItem("prevBook")) ? localStorage.getItem("prevBook") : "conversation1-es";
    const initBook = books.find((b) => b.key === initBookTitle);

    data.store.booksLoaded = true;
    data.store.book = initBookTitle;
    data.store.steps = initBook.steps;
    data.store.maxStep = initBook.steps.length;
    data.store.text = initBook.text;
    data.store.nativeText = initBook.nativeText;
    data.store.preLoadedSounds = preLoadSounds(initBookTitle)

    data.save();
});

const stringToBool = (bool) => {

    if (bool === "true") {
        return true;
    }

    return false;
};

const preLoadSounds = (title) => {
  
  const snds = {};

  for (let index = 0; index < data.store.text.length; index++) {
  
    const soundName = `/sounds/${title}-${index}.mp3`;

    snds[data.store.book + "-" + index] = new Audio(soundName);

  }
  
  return snds;
};

const data = {

    store: {

        booksLoaded: false,
        book: "conversation1-es",
        shouldShowBookSelector: false,
        seenPopup: (localStorage && localStorage.getItem("popupSeen")) ? true : false,
        step: (localStorage && localStorage.getItem("prevStep")) ? parseInt(localStorage.getItem("prevStep"), 10) : 1,
        steps: 1,
        maxStep: 1,
        text: "",
        nativeText: "",
        showFullTranslation: 0,
        volumeOn: (localStorage && localStorage.getItem("volumeOn") != null) ? stringToBool(localStorage.getItem("volumeOn")) : true,
        preLoadedSounds: {}
    },

    callbacks: [],

    onChange: (cb) => {
        data.callbacks.push(cb);
    },

    save: () => {
        data.callbacks.forEach(cb => {
            cb(data.store);
        });
    }
};

export { data, preLoadSounds };
