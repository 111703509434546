import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './DataLoading.css';

function DataLoading(props) {

  const nodeRef = useRef(null);

  return (
    
    <CSSTransition in={!props.booksLoaded} nodeRef={nodeRef} timeout={200} classNames="modalFade">
      <div className="DataLoading-container" ref={nodeRef}>
            <div className="DataLoading-subContainer">
                <img src="/donquixote-logo.png" alt="DonQuixote logo" className="DataLoading-img" />
                <br />
                <div>
                    ....loading, please wait
                </div>
            </div>
      </div>
    </CSSTransition>
  );
}

export default DataLoading;
