
const getDailyCacheBuster = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    return mm + dd + yyyy;
};

let Books = [

];

const BookLoader = {
    load: () => {

        return fetch("/books/books.json?t=" + getDailyCacheBuster(), {
            headers: {
                "Content-Type": "application/json"
            },
            method: "GET"
        }).then((response) => response.json())
        .then((json) => {
            
            Books = json.books;

            return BookLoader.loadText();
        });
    },
    loadText: () => {

        const promiseArray = [];

        Books.forEach((b) => {

            const bookTextPromise = fetch(`/books/${b.translation}?t=` + getDailyCacheBuster(), {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "GET"
            }).then((response) => response.json())
            .then((json) => {
                
                b.text = json.text;
                b.steps = json.steps;
                b.nativeText = json.nativeText || [];

                // console.log("b", b);
                
                SoundGenerator(b);

                return b;
            });

            promiseArray.push(bookTextPromise);
        });

        return Promise.all(promiseArray).then(() => {
            return Books;
        });
    }
};

let SoundObject = {};
const SoundGenerator = (book) => {

    SoundObject[book.key] = SoundObject[book.key] || { language: book.language, texts: [] };

    SoundObject[book.key].texts = SoundObject[book.key].texts.concat(book.text);
    SoundObject[book.key].language = book.language;

    window.SoundObject = SoundObject;
}

export { BookLoader, Books };
