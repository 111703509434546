import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './BookSelector.css';
import { Books } from "./Books";

function BookSelector(props) {

  const nodeRef = useRef(null);
  const [flag, setFlag] = useState("");

  const hidePopup = () => {

    props.hideBookSelector();
  };

  const changeFlag = (ev) => {
    setFlag(ev.target.value);
  };

  const toggleVolume = (ev) => {

    props.toggleVolume();
  };

  const selectedBook = Books.find((b) => { return b.key === props.book; });

  const selectedLanguage = selectedBook && !flag ? selectedBook.language : flag;

  let volumeIcon = props.volumeOn ? "volume_up" : "volume_mute";

  // console.log("vi", props.volumeOn, volumeIcon);

  return (
    
    <CSSTransition in={props.shouldShowBookSelector} nodeRef={nodeRef} timeout={200} classNames="modalFade">
      <div className="BookSelector-container" ref={nodeRef}>
        <div className="BookSelector-background" onClick={hidePopup} ref={nodeRef} />
        <div className="BookSelector">

          <div className="BookSelector-close">
            <button className="BookSelector-close-button" title="Close & begin" onClick={hidePopup}>
              <i className="material-icons">close</i>
            </button>
          </div>

          <div className="BookSelector-text">
              Choose text
          </div>

          <div className="BookSelector-remember">
            Remember to recite each step aloud to aid learning.
          </div>

          <select onChange={changeFlag} value={selectedLanguage} className="BookSelector-select" style={{ backgroundImage: `url('/${selectedLanguage}.png')` }}>
            <option value="france">French</option>
            <option value="german">German</option>
            <option value="italy">Italian</option>
            <option value="spain">Spanish</option>
          </select>

          <hr className="BookSelector-hr" />

          {Books.filter((b) => b.language === selectedLanguage).map((b) => {

            const isSelected = b.key === props.book;
            const css = isSelected ? "BookSelector-book BookSelector-selected" : "BookSelector-book";

            return <button key={b.key} className={css} onClick={props.chooseBook.bind(this, b)}>{b.title} {b.author && <i>by {b.author}</i>}</button>
          })}

          <div style={{ color: "#b1b1b1", textAlign: "right", padding: "20px 10px 0 0" }}>
            <button title="Toggle volume on/off" className="BookSelector-sub-link BookSelector-volume" onClick={toggleVolume} style={{ textAlign: "left", opacity: props.volumeOn ? 1 : 0.3 }}>
              <span className="material-icons BookSelector-sub-icon" style={{ paddingTop: "8px", marginLeft: 0 }}>{volumeIcon}</span>
            </button>
            <a href="/about" title="Learn more" className="BookSelector-sub-link BookSelector-info">
              <span className="material-icons BookSelector-sub-icon" style={{ paddingTop: "14px" }}>info_outline</span>
            </a>
            <a href="mailto:info@donquixote.fun" title="Email us" className="BookSelector-sub-link">
              <span className="material-icons BookSelector-sub-icon" style={{ paddingTop: "14px" }}>email</span>
            </a>
            <a href="https://twitter.com/DonQuixoteApp" rel="noreferrer noopener" target="_blank" title="Visit us on Twitter" className="BookSelector-sub-link BookSelector-twitter">
              <img src="/twitter.png" alt="Twitter Icon" className="BookSelector-twitter" />
            </a>
            <span style={{ display: "inline-block", paddingBottom: "8px" }}>
              &copy; {new Date().getFullYear().toString()} DonQuixote LLC
            </span>
          </div>
          
        </div>
      </div>
    </CSSTransition>
  );
}

export default BookSelector;
