import React, { useState, useEffect } from 'react';
import './App.css';
import Stepper from './Stepper';
import InitialPopup from './InitialPopup';
import { data, preLoadSounds } from "./Store";
import TextWindow from './TextWindow';
import BookSelector from './BookSelector';
import DataLoading from './DataLoading';
import { Books } from "./Books";

function App() {

  const [store, setStore] = useState(data.store);

  const handleSeenPopup = () => {
    data.store.seenPopup = true;
    data.save();
  }

  const persistStep = () => {

    if (localStorage) {
      localStorage.setItem("prevStep", data.store.step);
      localStorage.setItem("prevBook", data.store.book);
    }

  }

  const changeStep = (ev) => {

    stopAudio();
    const num = parseInt(ev.target.value, 10);
    const isANumber = !isNaN(num);
    const actualNumber = isANumber ? num : 0;

    data.store.step = Math.max(actualNumber, 1);
    data.store.step = Math.min(data.store.step, data.store.maxStep);
    
    persistStep();
    data.save();
  
  }

  const decrementStep = () => {

    stopAudio();
    data.store.step = Math.max(data.store.step - 1, 1);

    persistStep();
    data.save();
  }

  const incrementStep = () => {

    stopAudio();

    if (data.store.maxStep === data.store.step) {

      const selectedBook = Books.find((b) => { return b.key === data.store.book; });
      const booksForChosenLanguage = Books.filter((b) => b.language === selectedBook.language);
      const selectedIndex = booksForChosenLanguage.findIndex((b) => b.key === data.store.book);

      if (booksForChosenLanguage.length > selectedIndex + 1) {
        data.store.book = booksForChosenLanguage[selectedIndex + 1].key;
      } else {
        data.store.book = booksForChosenLanguage[0].key;
      }

      const nextBook = booksForChosenLanguage.find((b) => b.key === data.store.book);

      data.store.book = nextBook.key;
      data.store.text = nextBook.text;
      data.store.nativeText = nextBook.nativeText;
      data.store.steps = nextBook.steps;
      data.store.maxStep = nextBook.steps.length;

      data.store.step = 1;
      
    } else {

      data.store.step = Math.min(data.store.step + 1, data.store.maxStep);

      let stepIndex = data.store.step - 1;

      if (data.store.volumeOn && data.store.steps[stepIndex] && data.store.steps[stepIndex].isGreen) {
        let textIndex = getTextIndexByStartIndex(data.store.steps[stepIndex].startIndex);
        // console.log(data.store.preLoadedSounds);
        let audio = data.store.preLoadedSounds[`${data.store.book}-${textIndex}`] || new Audio(`/sounds/${data.store.book}-${textIndex}.mp3`);

        audio.play();
        // console.log(data.store, data.store.steps[data.store.step - 1], soundName);
      }
    }

    persistStep();
    data.save();
  };

  async function stopAudio() {
    Object.keys(data.store.preLoadedSounds).forEach((k) => {
      data.store.preLoadedSounds[k].pause();
      data.store.preLoadedSounds[k].currentTime = 0;
    })
  }

  const getTextIndexByStartIndex = (startIndex) => {

    let length = 0;

    for (let index = 0; index < data.store.text.length; index++) {
      const t = data.store.text[index];

      if (startIndex < length) {
        return index - 1;
      }

      length += t.length + 1;
      
    }

    return data.store.text.length - 1;
  };

  const showBookSelector = () => {
    data.store.shouldShowBookSelector = true;
    
    data.save();
  };

  const hideBookSelector = () => {
    data.store.shouldShowBookSelector = false;
    
    data.save();
  };

  const chooseBook = (b) => {

    data.store.book = b.key;
    data.store.shouldShowBookSelector = false;
    data.store.step = 1;
    data.store.text = b.text;
    data.store.nativeText = b.nativeText;
    data.store.steps = b.steps;
    data.store.maxStep = b.steps.length;
    // data.store.preLoadedSounds = {...data.store.preLoadedSounds, ...preLoadSounds(b.key)}; if we want to keep loading them
    data.store.preLoadedSounds = preLoadSounds(b.key);

    if (data.store.nativeText.length === 0) {
      data.store.showFullTranslation = 0;
    }

    persistStep();
    data.save();
  };

  const toggleVolume = () => {

    data.store.volumeOn = !data.store.volumeOn;
    localStorage.setItem("volumeOn", data.store.volumeOn);

    data.save();
  };

  const showFullTranslation = () => {

    if (data.store.showFullTranslation < 2) {
      data.store.showFullTranslation++;
    } else {
      data.store.showFullTranslation = 0;
    }

    data.save();
  };

  useEffect(() => {
    data.onChange((store) => {

      setStore(Object.assign({}, store));
    });
    
  }, []);

  // console.log("abc", store.volumeOn);

  return (
    <div className="App">
      <DataLoading booksLoaded={store.booksLoaded} />
      <InitialPopup setSeenPopup={handleSeenPopup} seenPopup={store.seenPopup} />
      <TextWindow
        step={store.step}
        steps={store.steps}
        text={store.showFullTranslation === 2 ? store.nativeText : store.text}
        seenPopup={store.seenPopup}
        shouldShowFullTranslation={store.showFullTranslation}
      />
      {store.seenPopup && <Stepper
        decrementStep={decrementStep}
        incrementStep={incrementStep}
        changeStep={changeStep}
        step={store.step}
        maxStep={store.maxStep}
        showBookSelector={showBookSelector}
        showFullTranslation={showFullTranslation}
        shouldShowFullTranslation={store.showFullTranslation}
        shouldAllowShowFullTranslation={store.nativeText && store.nativeText.length > 0}
      />}
      {store.seenPopup && <BookSelector
        hideBookSelector={hideBookSelector}
        shouldShowBookSelector={store.shouldShowBookSelector}
        book={store.book}
        chooseBook={chooseBook}
        toggleVolume={toggleVolume}
        volumeOn={store.volumeOn}
      />}
    </div>
  );
}

export default App;
