import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './InitialPopup.css';

function InitialPopup(props) {

  let seenPopupPreviously = localStorage && localStorage.getItem("popupSeen");
  const nodeRef = useRef(null);

  const hidePopup = () => {

    props.setSeenPopup();
    
    setTimeout(() => {
      
      if (localStorage) {
        localStorage.setItem("popupSeen", true);
      }
    }, 1);
  };

  if (seenPopupPreviously) {
    return <React.Fragment />;
  }

  // setTimeout(() => {
  //   hidePopup();
  // }, 40000);

  return (
    
    <CSSTransition in={!props.seenPopup} nodeRef={nodeRef} timeout={200} classNames="fadeOut">
      <div className="InitialPopup-container" ref={nodeRef}>
        <div className="InitialPopup-background" onClick={hidePopup} />
        <div className="InitialPopup">

          <div className="InitialPopup-close">
            <button className="InitialPopup-close-button" title="Close & begin" onClick={hidePopup}>
              <i className="material-icons">close</i>
            </button>
          </div>
          <p className="InitialPopup-image">
            <picture>
              <source srcSet="/don-quixote-small.jpg" media="(min-height: 600px)"/>
              <img src="/don-quixote-small.jpg" alt="Don Quixote" />
            </picture>
          </p>
          <p className="InitialPopup-intro">
            Welcome to <b>donquixote.fun</b>, a novel way of learning languages quickly.
            We only have a few translations right now, but stay tuned. Read the following suggestions and then begin. Good luck!
          </p>
          <ul>
            <li><span className="material-icons checkbox">check_circle</span> Recite the <span className="InitialPopup-english">Primary</span> and <span className="InitialPopup-spanish">Foreign</span> language in the highlighted sections out loud</li>
            <li><span className="material-icons checkbox">check_circle</span> When you get to a <span className="InitialPopup-greenText">green</span> highlighted item, you should understand the meaning</li>
            <li><span className="material-icons checkbox">check_circle</span> If you pause, remember the step number you left off, so you can resume your position later</li>
            <li><span className="material-icons checkbox">check_circle</span> Choose a different text if you'd like by clicking the book icon</li>
          </ul>
          <div className="InitialPopup-begin">
            <button className="InitialPopup-begin-button" onClick={hidePopup}>
              BEGIN
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default InitialPopup;
