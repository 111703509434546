import React from 'react';
import { Route, Switch } from "wouter";
import Info from "./Info";
import App from "./App";

function Routes(props) {

  return (
    
    <Switch>
        <Route path="/about">
            <Info />
        </Route>
        <Route>
            <App />
        </Route>
    </Switch>
  );
}

export default Routes;
