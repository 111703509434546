import classNames from 'classnames';
import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './TextWindow.css';

function TextWindow(props) {

  const nodeRef = useRef(null);
  const highlightRef = useRef(null);

  const executeScroll = () => {
  
    setTimeout(() => {

      if (highlightRef.current) {
          highlightRef.current.scrollIntoView();
      }
    }, 1);
      
  };

  const currentStep = props.steps[props.step - 1];

  if (!props.text || !currentStep) {
    return <React.Fragment />;
  }

  let text = props.text.join("|"); // we joined all paragraphs as one string

  if (props.shouldShowFullTranslation === 0) {

    let nativeText = currentStep ? currentStep.nativeText : "";
    let newPhrase = currentStep ? currentStep.newPhrase : "";
    let newLanguageText = currentStep ? currentStep.newLanguageText : "";
  
    let subText = currentStep ? "$$" + nativeText + "^^" + newPhrase + "^^" + newLanguageText + "$$" : ""; // the text we want to insert
  
    if (props.seenPopup && currentStep) {
  
      text = text.substr(0, currentStep.startIndex) + subText + text.substr(currentStep.endIndex); // insert the new text
    }
  }

  text = text.split("|"); // turn it back into an array of paragraphs

  executeScroll();

  const tangentialCSS = classNames({
    "TextWindow": true,
    "TextWindow-tan": props.seenPopup,
    "TextWindow-fullForeign": props.shouldShowFullTranslation === 1 || props.shouldShowFullTranslation === 2
  });

  return (
    
    <CSSTransition in={true} nodeRef={nodeRef} timeout={200} classNames="fadeOut">
      <div className={tangentialCSS} ref={nodeRef}>
        
        {text.map((paragraph, paragraphIndex) => {
            if (paragraph.indexOf("$$") !== -1) {

                const highlightedTextStartIndex = paragraph.indexOf("$$");
                const highlightedTextEndIndex = paragraph.lastIndexOf("$$") - 2;

                const searchRegExp = /\$\$/g;
                paragraph = paragraph.replace(searchRegExp, "");
                let highlightedText = paragraph.substring(highlightedTextStartIndex, highlightedTextEndIndex);
                // console.log(highlightedText, highlightedTextStartIndex, highlightedTextEndIndex);
                let highlighCSS = currentStep.isGreen ? "TextWindow-highlightGreen" : "TextWindow-highlight";
                let title = currentStep.isGreen ? "You should have a decent level of understanding of the text when its highlighted green" : "";

                const highlightSearchRegEx = /\^\^/g;
                const highlightSections = highlightedText.split("^^");
                const nativeText = highlightSections[0];
                const newPhrase = highlightSections[1];
                const newLanguageText = highlightSections[2];
                highlightedText = highlightedText.replace(highlightSearchRegEx, "");
                paragraph = paragraph.replace(highlightSearchRegEx, "");

                paragraph = <p key={"p-" + paragraphIndex} className="TextWindow-p">
                    {paragraph.substr(0, highlightedTextStartIndex)}
                    <span ref={highlightRef} className={highlighCSS} title={title}>
                      <span className="TextWindow-nativeText">{nativeText}</span>
                      <span className="TextWindow-newPhrase">{newPhrase}</span>
                      <span className="TextWindow-newLanguageText">{newLanguageText}</span>
                    </span>
                    {paragraph.substr(highlightedTextStartIndex + highlightedText.length)}
                </p>;

                return paragraph;
            }

            paragraph = paragraph.indexOf("(((") !== -1 ? <i className="TextWindow-i">{paragraph.replace(/\(\(\(/g, "").replace(/\)\)\)/g, "")}</i> : paragraph;

            return <p key={"p-" + paragraphIndex} className="TextWindow-p">{paragraph}</p>;
        })}
      </div>
    </CSSTransition>
  );
}

export default TextWindow;
