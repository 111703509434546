import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Stepper.css';
import classNames from "classnames";

function Stepper(props) {

  const nodeRef = useRef(null);

  const focus = (ev) => {
    ev.target.select();
  };

  const keyUp = (ev) => {
    if (ev.key === "Enter") {
      ev.target.blur();
    }
  };

  const prevStepsCSS = classNames({
    "Stepper-button": true,
    "Stepper-disable": props.step <= 1 || props.shouldShowFullTranslation !== 0
  });

  const nextStepsCSS = classNames({
    "Stepper-button": true,
    "Stepper-done": props.step === props.maxStep,
    "Stepper-disable": props.shouldShowFullTranslation !== 0
  });

  const translateIconCSS =  classNames({
    "Stepper-button": true,
    "Stepper-book": true,
    "Stepper-foreign": props.shouldShowFullTranslation === 1,
    "Stepper-domestic": props.shouldShowFullTranslation === 2
  });

  return (
    
    <CSSTransition in={true} nodeRef={nodeRef} timeout={200} classNames="fadeOut">
      <div className="Stepper" ref={nodeRef}>
        {props.shouldAllowShowFullTranslation && <button className={translateIconCSS} onClick={props.showFullTranslation} title="View entire text in your primary/foreign language" style={{ marginRight: "-10px" }}>
            <span className="material-icons">translate</span>
        </button>}
        <button className="Stepper-button Stepper-book" onClick={props.showBookSelector} title="Change text">
            <span className="material-icons">book</span>
        </button>
        <button disabled={props.shouldShowFullTranslation !== 0} className={prevStepsCSS} onClick={props.decrementStep}>
            <span className="material-icons">chevron_left</span>
        </button>
        <input type="text"
          className="Stepper-stepNum"
          maxLength={6}
          onChange={props.changeStep}
          onFocus={focus}
          onKeyUp={keyUp}
          value={props.step}
          pattern="^[0-9]*$"
          disabled={props.shouldShowFullTranslation !== 0}
        />
        <button disabled={props.shouldShowFullTranslation !== 0} className={nextStepsCSS} onClick={props.incrementStep}>
          
            <CSSTransition in={props.step !== props.maxStep} nodeRef={nodeRef} timeout={200} classNames="Stepper-show-chevron">
              <span className="material-icons mi-chevron-right">chevron_right</span>
            </CSSTransition>
            <CSSTransition in={props.step === props.maxStep} nodeRef={nodeRef} timeout={400} classNames="Stepper-show-done">
              <span title="You finished the text. Click here to go to the next text." className="material-icons mi-done">done</span>
            </CSSTransition>
        </button>

      </div>
    </CSSTransition>
  );
}

export default Stepper;
